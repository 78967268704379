import { Link } from "gatsby";
import React from "react";

function Footer() {

  return (
    <footer>
      <div className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            {[
              {
                route: `/zones-d-interventions-sur-toiture/`,
                title: `Zone d'intervention`,
              },
              {
                route: `/mentions-legales/`,
                title: `Mentions légales`,
              },
              {
                route: `/contactez-nous/`,
                title: `Contactez nous`,
              },
            ].map((link) => (
              <div className="px-5 py-2" key={link.title}>
                <Link
                  className="text-base leading-6 text-gray-900 hover:text-gray-600"
                  key={link.title}
                  to={link.route}
                  title={link.title}
                >
                  {link.title}
                </Link>
              </div>
            ))}
          </nav>
          <div className="mt-8">
            <p className="text-center text-base leading-6 text-gray-900">
              &copy; 2020 Evotoit SAS. Tous droits réservés. | Réalisation:{" "}
              <a
                href="https://www.timetobefirst.com"
                rel="noopener noreferrer"
                target="_blank"
                className="text-sm leading-6"
                title="Agence Time To Be First"
              >
                Agence Time To Be First
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;